import React, { Component, useState, useEffect, useContext, useReducer } from 'react';
import './App.css';
import './tailwind.generated.css'
import { auth } from './firebase.js';
import { BrowserRouter as Router, Route, Redirect, useHistory, useParams, Switch } from "react-router-dom";
import { StripeProvider, injectStripe, Elements } from 'react-stripe-elements';
import axios from "axios";

function Header(props) {
  // const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  function goHome() {
    // history.push(`/callboxes`);
  }

  return (
    <header className="bg-red-400 flex items-center justify-between flex-wrap p-2">
      <div className="pt-3 pl-10">
          <span className="text-5xl text-gray-700">
            Callboxer
          </span>
      </div>
      <div className="block lg:hidden">
        <button
          onClick={toggleMenu}
          className="flex items-center px-3 py-2 border rounded text-gray-700 border-gray-700 hover:text-white hover:border-white">
          <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
          </svg>
        </button>
      </div>
      <div className={`${isMenuOpen ? "" : "hidden"} w-full block lg:flex lg:items-center lg:w-auto`}>
        <div>
          <button onClick={goHome} className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-white mr-4">
            Home
          </button>

          {props.user ?
            <button className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-white mr-4" onClick={props.logout}>
              Log out
            </button>
            :
            <button className="block mt-4 lg:inline-block lg:mt-0 text-gray-700 hover:text-white mr-4" onClick={props.login}>
              Log in
            </button>
          }
        </div>
      </div>
    </header>
  );
}

class Checkout extends Component {

  constructor(props) {
    super(props);

    this.startCheckout = this.startCheckout.bind(this);

    this.state = {};
  }

  startCheckout() {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/checkout`
      : `/api/checkout`;
    fetch(uri, {
      method: "POST",
      "headers": {
        Authorization: "Bearer: " + this.props.user.token
      },
      body: JSON.stringify({})
    }).then((response) => {
      return response.json();
    }).then((body) => {

      console.log('sessionId', body.sessionId);

      this.props.stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
        sessionId: body.sessionId
      }).then(function (result) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        console.log(result);
      });
    });
  }

  render() {
    return (
      <div>
        <p>Click to checkout</p>
        <button className="big-button-with-border" onClick={this.startCheckout}>Checkout</button>
      </div>
    );
  }
}

function Success(props) {
  return <div>Success</div>
}

function Cancelled(props) {
  return <div>Cancelled</div>
}

const RadioButtonContext = React.createContext();

function RadioButtonGroup(props) {

  const [selected, setSelected] = useState(props.initialSelected);

  const onChange = (s) => {
    console.log(s);
    setSelected(s);

    if (props.onChange) {
      props.onChange(s);
    }
  };

  return (
    <RadioButtonContext.Provider value={{
      onChange,
      selected
    }}>
      {props.children}
    </RadioButtonContext.Provider>
  );
}

function RadioButton(props) {

  const radioGroup = useContext(RadioButtonContext);

  return (
    <button
      onClick={() => {
        radioGroup.onChange(props.id)
      }}
      className={`bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded ${radioGroup.selected === props.id ? 'bg-blue-600' : ''}`}>
      {props.title}
    </button>
  );
}

function BasicCallboxes(props) {

  const [callboxes, setCallboxes] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/callboxes`
      : `/api/callboxes`;
    fetch(uri, {
      headers: {
        Authorization: "Bearer: " + props.user.token
      }
    }).then((response) => {
      return response.json();
    }).then((callboxes) => {
      setCallboxes(callboxes);
    })
    // eslint-disable-next-line
  }, []);

  const setUpNewCallbox = () => {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/callboxes`
      : `/api/callboxes`;
    fetch(uri, {
      method: "POST",
      "headers": {
        Authorization: "Bearer: " + props.user.token
      },
      body: JSON.stringify({
      })
    }).then((response) => {
      return response.json();
    }).then((callbox) => {
      history.push(`/callboxes/${callbox.id}/edit`);
    });
  };

  return (
    <div className="flex flex-wrap justify-center">
      {
        callboxes.map((callbox) => {
          return [
            <CallboxCard key={`${callbox.number}:${callbox.pin}`} {...callbox} user={props.user}/>
          ]
        })
      }
      <div>
        <button
          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
          onClick={setUpNewCallbox}
        >
          Create Callbox
        </button>
      </div>
    </div>
  );
}

class Callboxes extends Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount() {

    debugger;
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/user`
      : `/api/user`;
    fetch(uri, {
      headers: {
        Authorization: "Bearer: " + this.props.user.token
      }
    }).then((response) => {
      return response.json();
    }).then((userDetails) => {
      this.setState(
        Object.assign({}, this.state, {
          licenses: userDetails.licenses,
        })
      );
    });
  }

  render() {
    return (
      <BasicCallboxes licenses={this.state.licenses} user={this.props.user}/>
    );
  }
}

function PhoneNumber(props) {

  function formatPhoneNumber(phoneNumber) {
    console.log(phoneNumber);
    return `(${phoneNumber.substring(2, 5)}) ${phoneNumber.substring(5, 8)}-${phoneNumber.substring(8, 12)}`;
  }

  return (
    <div>
      <p className="text-gray-700 text-base mb-4">{formatPhoneNumber(props.number)}</p>
    </div>
  );
}

function CallboxCard(props) {

  const history = useHistory();

  function manageCallbox(e) {
    const un = e.target.value;
    console.log('username: ', un);

    history.push(`/callboxes/${props.id}`);
  }

  return (
    <div className="m-5 max-w-sm rounded overflow-hidden shadow-lg">
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{props.name || props.id}</div>
        {
          props.phoneInfo && props.phoneInfo.phoneNumber
            ? <PhoneNumber number={props.phoneInfo && props.phoneInfo.phoneNumber}/>
            : <p></p>
        }
        <div className="flex justify-end">
          <button
            onClick={manageCallbox}
            className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
            Manage
          </button>
        </div>
      </div>
    </div>
  );
}

function CallboxManagement(props) {

  const [callbox, setCallbox] = useState(null);
  // const [isEditing, setIsEditing] = useState(false);

  const history = useHistory();

  const { callboxId } = useParams();

  useEffect(() => {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/callboxes/${callboxId}`
      : `/api/callboxes/${callboxId}`;
    fetch(uri, {
      headers: {
        Authorization: "Bearer: " + props.user.token
      }
    })
    .then((response) => {
      return response.json();
    })
    .then((result) => {
      setCallbox(result);
      return result;
    });
    // eslint-disable-next-line
  }, []);

  const editCallbox = () => {
    console.log('edit');
    history.push(`/callboxes/${callboxId}/edit`);
  };

  return (
    <div className="flex justify-center">
      <div className="m-5 max-w-xl rounded overflow-hidden shadow-lg">
        <div className="px-6 py-4">
            <div className="font-bold text-2xl mb-2">{(callbox && callbox.name) || "Loading..."}</div>
            {
              callbox && callbox.phoneInfo
                ? <PhoneNumber number={callbox.phoneInfo.phoneNumber}/>
                : <p/>
            }
            {
              callbox
                ? <Config user={props.user} configId={callbox.configId}/>
                : <p>Loading...</p>
            }
          <button
            onClick={editCallbox}
            className={`w-full bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}>
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}

function editCallboxReducer(state, action) {
  
  switch (action.type) {
    case 'modify-config': {
      const configId = action.payload.id;
      const config = action.payload;

      

      const configs = {
        ...state.configs,
        [configId]: {
          ...config
        }
      };

      return {
        ...state,
        configs
      };
    }
    case 'modify-strategy': {
      return {
        ...state,
      };
    }
    case 'modify-name':
      return {
        ...state,
        name: action.payload.name
      };
    default:
      throw new Error();
  }
}

function EditCallbox(props) {

  const history = useHistory();

  const [callbox, setCallbox] = useState(null);

  const [state, dispatch] = useReducer(editCallboxReducer, {});

  const { callboxId } = useParams();

  useEffect(() => {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/callboxes/${callboxId}`
      : `/api/callboxes/${callboxId}`;
    fetch(uri, {
      headers: {
        Authorization: "Bearer: " + props.user.token
      }
    }).then((response) => {
      return response.json();
    }).then((result) => {
      setCallbox(result);
      dispatch({
        type: 'modify-name',
        payload: {
          name: result.name
        }
      });
      return result;
    });
    // eslint-disable-next-line
  }, []);

  const onClickSave = () => {
    console.log('saving');
    console.log(JSON.stringify(state));

    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/callboxes/${callboxId}`
      : `/api/callboxes/${callboxId}`;
    axios({
      method: "PUT",
      url: uri,
      headers: {
        Authorization: "Bearer: " + props.user.token
      },
      data: {
        ...callbox,
        name: state.name || callbox.name
      }
    }).then(() => {
      const configsToUpdate = state.configs ? Object.keys(state.configs) : [];
      const updatedConfigs = configsToUpdate.map((configId) => {
        const c = state.configs[configId];

        const uri = process.env.REACT_APP_API_DOMAIN
          ? `${process.env.REACT_APP_API_DOMAIN}/api/configs/${c.id}`
          : `/api/configs/${c.id}`;

        return axios({
          method: "PUT",
          url: uri,
          headers: {
            Authorization: "Bearer: " + props.user.token
          },
          data: {
            ...c
          }
        })
      });

      return Promise.all(updatedConfigs);
    })
      .then(() => {
        history.push(`/callboxes/${callboxId}`);
      });
  };

  return (
    <div className="flex justify-center">
      <div className="m-5 max-w-xl rounded overflow-hidden shadow-lg">
        <div className="px-6 py-4">
          <div className="font-bold text-2xl mb-2">
            {
              callbox
                ? (
                    callbox.phoneInfo && callbox.phoneInfo.phoneNumber
                      ? <PhoneNumber number={callbox.phoneInfo.phoneNumber}/>
                      : <p>New Callbox</p>
                )
                : <p>Loading...</p>
            }
          </div>
          {
            callbox
              ? <div>
                  <form className="w-full max-w-lg">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                          Name
                        </label>
                        <input
                          onChange={(e) => {
                            dispatch({
                              type: 'modify-name',
                              payload: {
                                name: e.target.value
                              }
                            })
                          }}
                          value={state.name}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="callbox-name" type="text" placeholder="My Apartment"/>
                      </div>
                    </div>
                  </form>
                </div>
              : <p>Loading...</p>
          }
          {
            callbox
              ? <EditConfig user={props.user} configId={callbox.configId} state={state} dispatch={dispatch}/>
              : <p>Loading...</p>
          }
          <button
            onClick={onClickSave}
            className={`w-full bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded`}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
}

function EditConfig(props) {

  useEffect(() => {
    if (!props.state.configs || !props.state.configs[props.configId]) {
      const uri = process.env.REACT_APP_API_DOMAIN
        ? `${process.env.REACT_APP_API_DOMAIN}/api/configs/${props.configId}`
        : `/api/configs/${props.configId}`;

      fetch(uri, {
        headers: {
          Authorization: "Bearer: " + props.user.token
        }
      }).then((response) => {
        return response.json()
      }).then((cfg) => {
        
        props.dispatch({
          type: "modify-config",
          payload: cfg
        });
        return cfg;
      });
    }
    // eslint-disable-next-line
  }, []);

  const updateStrategy = (id) => {
    const config = props.state.configs[props.configId];
    props.dispatch({
      type: 'modify-config',
      payload: {
        ...config,
        strategy: id
      }
    });
  };

  const configByType = function(config) {
    switch (props.state.configs[props.configId].strategy) {
      case "allow":
        return (
          <EditAllowConfig config={config} dispatch={props.dispatch}/>
        );
      case "multi-ring":
        return (
          <EditMultiRingConfig config={config} dispatch={props.dispatch}/>
        );
      case "pin":
        return (
          <EditPinConfig config={config} dispatch={props.dispatch}/>
        );
      default:
        return (
          <div><pre>{JSON.stringify(config, null, 2) }</pre></div>
        );
    }
  };

  return (
    <div>
      {
        props.state && props.state.configs && props.state.configs[props.configId]
          ?
           <div>
             <RadioButtonGroup onChange={(id) => {
               updateStrategy(id)
             }} initialSelected={props.state.configs[props.configId].strategy}>
               <RadioButton id="allow" title="Auto-Open"/>
               <RadioButton id="multi-ring" title="Multi-Ring"/>
               <RadioButton id="pin" title="PIN"/>
             </RadioButtonGroup>
             <div className="px-6 py-4">
               { configByType(props.state.configs[props.configId]) }
             </div>
           </div>
          :
          <p>loading...</p>
      }
    </div>
  );
}

function EditAllowConfig(props) {
  return (
    <div>
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
              Digit To Open
            </label>
            <div className="relative">
              <select
                defaultValue={Number(props.config.digitToOpen)}
                onChange={(e) => {
                  
                  props.dispatch({
                    type: "modify-config",
                    payload: {
                      ...props.config,
                      digitToOpen: e.target.value
                    }
                  })
                }}
                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                id="grid-state">
                {
                  [...Array(10).keys()].map((num) => {
                    return <option key={num}>{num}</option>
                  })
                }
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

function EditMultiRingConfig(props) {

  const [numbers, setNumbers] = useState({});

  useEffect(() => {
    const numbersToUse = props.config.numbers || [];
    const numbers = numbersToUse.reduce((memo, num, idx) => {
      return {
        ...memo,
        [idx]: {
          phoneNumber: num
        }
      }
    }, {});

    setNumbers(numbers);
    // eslint-disable-next-line
  }, []);

  const onPhoneNumberChange = (e, numKey) => {
    const value = e.target.value;

    const numbersUpdate = {
      ...numbers,
      [numKey]: {
        phoneNumber: value
      }
    };

    setNumbers(numbersUpdate);

    const numbersToSet = Object.keys(numbersUpdate).map((key) => {
      const num = numbersUpdate[key];

      return num.phoneNumber;
    });

    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        numbers: numbersToSet
      }
    })
  };

  const addPhoneNumber = () => {
    const numbersLength = Object.keys(numbers).length;

    console.log(numbers, numbersLength);

    const numbersUpdate = {
      ...numbers,
      [numbersLength]: {
        phoneNumber: ''
      }
    };

    setNumbers(numbersUpdate);

    const numbersToSet = Object
      .keys(numbersUpdate)
      .map((key) => {
        const num = numbersUpdate[key];

        return num.phoneNumber;
      })
      .filter((phoneNumber) => {
        return !!phoneNumber;
      });

    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        numbers: numbersToSet
      }
    })
  };

  const onAddPhoneNumber = (e) => {
    e.preventDefault();

    addPhoneNumber();
  };

  const onPhoneNumberDelete = (e, numKey) => {
    e.preventDefault();

    const numbersUpdate = {
      ...numbers,
    };

    delete numbersUpdate[numKey];

    setNumbers(numbersUpdate);

    const numbersToSet = Object.keys(numbersUpdate).map((key) => {
      const num = numbersUpdate[key];

      return num.phoneNumber;
    });
    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        numbers: numbersToSet
      }
    })
  };

  return (
    <div>
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
          {
            numbers
              ? Object.keys(numbers).map((numKey) => {
                  const number = numbers[numKey];
                  return [
                    <div key={numKey}>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Name
                      </label>
                      <div className="flex flex-row">
                        <input
                          onChange={(e) => {
                            onPhoneNumberChange(e, numKey)
                          }}
                          value={number.phoneNumber}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="callbox-name" type="text" placeholder="+15555555555"/>
                        <button
                          onClick={(e) => {
                            onPhoneNumberDelete(e, numKey)
                          }}
                          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white m-1 p-1 border border-blue-500 hover:border-transparent rounded">
                          X
                        </button>
                      </div>
                    </div>
                  ];
              })
              : <p>Loading...</p>
          }
            <button
              onClick={onAddPhoneNumber}
              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
              Add Phone Number
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function EditPinConfig(props) {

  const [pins, setPins] = useState({});

  useEffect(() => {
    const pinsToUse = props.config.pins || [];
    const pins = pinsToUse.reduce((memo, pin, idx) => {
      return {
        ...memo,
        [idx]: {
          pin: pin
        }
      }
    }, {});

    setPins(pins);
    // eslint-disable-next-line
  }, []);

  const onPinChange = (e, pinKey) => {
    const value = e.target.value;

    const pinsUpdate = {
      ...pins,
      [pinKey]: {
        pin: value
      }
    };

    setPins(pinsUpdate);

    const pinsToSet = Object.keys(pinsUpdate).map((key) => {
      const pin = pinsUpdate[key];

      return pin.pin;
    });
    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        pins: pinsToSet
      }
    })
  };

  const addPin = () => {
    const pinsLength = Object.keys(pins).length;

    console.log(pins, pinsLength);

    const pinsUpdate = {
      ...pins,
      [pinsLength]: {
        pin: ''
      }
    };

    setPins(pinsUpdate);

    const pinsToSet = Object
      .keys(pinsUpdate)
      .map((key) => {
        const pin = pinsUpdate[key];

        return pin.pin;
      })
      .filter((pin) => {
        return !!pin;
      });
    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        pins: pinsToSet
      }
    })
  };

  const onAddPin = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(pins));

    addPin();
  };

  const onPinDelete = (e, pinKey) => {
    e.preventDefault();

    const pinsUpdate = {
      ...pins,
    };

    delete pinsUpdate[pinKey];

    setPins(pinsUpdate);

    const pinsToSet = Object.keys(pinsUpdate).map((key) => {
      const pin = pinsUpdate[key];

      return pin.pin;
    });
    
    props.dispatch({
      type: "modify-config",
      payload: {
        ...props.config,
        pins: pinsToSet
      }
    })
  };

  return (
    <div>
      <form className="w-full max-w-lg">
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            {
              pins
                ? Object.keys(pins).map((pinKey) => {
                  const pin = pins[pinKey];
                  return [
                    <div key={pinKey}>
                      <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                        Pin
                      </label>
                      <div className="flex flex-row">
                        <input
                          onChange={(e) => {
                            onPinChange(e, pinKey)
                          }}
                          value={pin.pin}
                          className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="callbox-name" type="text" placeholder="1234"/>
                        <button
                          onClick={(e) => {
                            onPinDelete(e, pinKey)
                          }}
                          className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white m-1 p-1 border border-blue-500 hover:border-transparent rounded">
                          X
                        </button>
                      </div>
                    </div>
                  ];
                })
                : <p>Loading...</p>
            }
            <button
              onClick={onAddPin}
              className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
              Add PIN
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

function Config(props) {

  const [config, setConfig] = useState(null);

  useEffect(() => {
    const uri = process.env.REACT_APP_API_DOMAIN
      ? `${process.env.REACT_APP_API_DOMAIN}/api/configs/${props.configId}`
      : `/api/configs/${props.configId}`;
    fetch(uri, {
      headers: {
        Authorization: "Bearer: " + props.user.token
      }
    }).then((response) => {
      return response.json()
    }).then((cfg) => {
      setConfig(cfg);
      return cfg;
    });
    // eslint-disable-next-line
  }, []);

  const configByType = function(config) {
    switch (config.strategy) {
      case "allow":
        return (
          <AllowConfig config={config}/>
        );
      case "multi-ring":
        return (
          <MultiRingConfig config={config}/>
        );
      case "pin":
        return (
          <PinConfig config={config}/>
        );
      default:
        return (
          <div><pre>{JSON.stringify(config, null, 2) }</pre></div>
        );
    }
  };

  return (
    <div className="px-6 py-4">
      {
        config
          ? configByType(config)
          : <p>loading...</p>
      }
    </div>
  );
}

function AllowConfig(props) {
  return (
    <div>
      <h1>Strategy: Auto-open</h1>
      <p>Opener Digit: {props.config.digitToOpen}</p>
    </div>
  );
}

function MultiRingConfig(props) {
  return (
    <div>
      <h1>Strategy: Multi-Ring</h1>
      {
        props.config.numbers.map((number) => {
          return [
            <PhoneNumber key={number} number={number}/>,
          ]
        })
      }
    </div>
  );
}

function PinConfig(props) {
  return (
    <div>
      <h1>Strategy: Pin</h1>
      {
        props.config.pins.map((pin) => {
          return [
            <p key={pin}>PIN: {pin}</p>,
          ]
        })
      }
    </div>
  );
}

function ProtectedRoute({component: Component, ...rest}) {
  return (
    <div>
      {/*TODO: The firebase auth takes a second to set up and redirects us to login before auth is set */}
      <Route {...rest} render={(props)=> (
        rest.user
          ? <Component {...props} {...rest} />
          : <Redirect to="login"/>
      )} />
    </div>
  )
}

function Login(props) {
  const history = useHistory();

  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  function handleUsernameChange(e) {
    const un = e.target.value;
    console.log('username: ', un);

    setUsername(un);
  }

  function handlePasswordChange(e) {
    const pw = e.target.value;
    console.log('password: ', pw);

    setPassword(pw);
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(username, password);


    props.login(username, password)
      .then(() => {
        history.push("/callboxes");
      });
  }

  return (
    <form onSubmit={handleSubmit} className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
          Username
        </label>
        <input
          onChange={handleUsernameChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="username" type="text" placeholder="Username"/>
      </div>
      <div className="mb-6">
        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
          Password
        </label>
        <input
          onChange={handlePasswordChange}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
          id="password" type="password" placeholder="******************"/>
        {/*<p className="text-red-500 text-xs italic">Please choose a password.</p>*/}
      </div>
      <div className="flex items-center justify-between">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit">
          Sign In
        </button>
        {/*<a className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" href="#">*/}
        {/*  Forgot Password?*/}
        {/*</a>*/}
      </div>
    </form>
  );
}

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      waitingForState: true
    };

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);

    // TODO: Initially render a loading sign, then load the routes

    auth.onAuthStateChanged((user) => {
      console.log('authStateChanged', user);
      if (user) {
        user.getIdToken()
          .then((token) => {
            this.setState({
              waitingForState: false,
              user: {
                token
              }
            })
          })
          .catch((err) => {
            console.log('authStateChanged failure', user, err);
            this.setState({
              waitingForState: false,
            })
          });
      } else {
        this.setState({
          waitingForState: false,
        })
      }
    })
  }

  render() {
    if (this.state.waitingForState) {
      console.log("waiting for state");
      return (
        <div>Loading...</div>
      );
    } else {
      console.log("waitedfor state");
      return (
        <StripeProvider apiKey="pk_test_wb8IbofdppwlikivT8rGdNkA00nNr3i3Dr">
          <Elements>
            <div className="flex flex-col min-h-screen bg-white">
              <Header user={this.state.user} logout={this.logout} login={this.login}/>

              <Router>
                <main className="flex-grow">
                  <Switch>
                    <ProtectedRoute path="/checkout" component={injectStripe(Checkout)} user={this.state.user}/>
                    <Route path="/success" component={Success} />
                    <Route path="/cancelled" component={Cancelled} />
                    <Route path="/login"
                           render={(props) => <Login login={this.login}/>}
                    />
                    <ProtectedRoute path="/callboxes/:callboxId/edit" component={EditCallbox} user={this.state.user}/>
                    <ProtectedRoute path="/callboxes/:callboxId" component={CallboxManagement} user={this.state.user}/>
                    <ProtectedRoute path="/callboxes" component={Callboxes} user={this.state.user}/>
                  </Switch>
                </main>
              </Router>

              <footer>
                <p>Powered by... something</p>
              </footer>

            </div>
          </Elements>
        </StripeProvider>
      );
    }
  }

  componentDidMount() {}

  logout() {
    return auth.signOut()
      .then(() => {
        this.setState({
          user: null
        })
      });
  }

  login(email, password) {
    return auth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        const user = result.user;
        this.setState({
          user
        });
      })
  }
}

export default App;
