import firebase from 'firebase'

const config = {
  apiKey: "AIzaSyCmsreMV6OUc9HI10QEB4iWeMEg_1txqyw",
  authDomain: "callboxer.firebaseapp.com",
  databaseURL: "https://callboxer.firebaseio.com",
  projectId: "callboxer",
  storageBucket: "callboxer.appspot.com",
  messagingSenderId: "32866568451"
};
firebase.initializeApp(config);

export default firebase;

export const auth = firebase.auth();